import { template as template_bb96742a926340839e09a79e99f3b38b } from "@ember/template-compiler";
export default template_bb96742a926340839e09a79e99f3b38b(`
  <li class='row'>
    <span class='row__label'>
      {{@label}}
    </span>
    <span class='row__value'>
      {{@value}}
    </span>
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
